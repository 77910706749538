<script>
import jQuery from "jquery";
import api from "@/api";

require("jquery-ui/ui/core.js");
require("jquery-ui/ui/widget.js");
require("jquery-ui/ui/position.js");
require("jquery-ui/ui/widgets/menu.js");
require("jquery-ui/ui/widgets/autocomplete.js");

export default {
  name: "ArtistBlacklist",
  data: function () {
    return {
      blacklist: [],
    };
  },
  methods: {
    getArtistBlacklist: function () {
      const url = "/api/manager/ArtistBlacklist/";
      api.Manager.axios.get(url).then((response) => {
        this.blacklist = response.data.results;
      });
    },
  },
  created: function () {
    this.getArtistBlacklist();
    jQuery(document).ready(function () {
      const url = "/api/SiteGroups/";
      api.axios.get(url).then((response) => {
        var source = [];
        for (const item of response.data.results) {
          source.push(item.name);
        }
        jQuery("#blacklist_artist").autocomplete({
          source: source,
        });
      });
    });
  },
};
</script>

<template>
  <div id="ArtistBlacklist" class="container">
    <div class="row justify-content-center">
      <template v-if="blacklist.length">
        <table class="col-12 table table-bordered">
          <thead>
            <th>Artist</th>
            <th>Location</th>
            <th>Amount</th>
            <th>Payment Method</th>
            <th>Start Time</th>
          </thead>
          <tbody>
            <tr v-for="payment in blacklist" :key="payment.id">
              <td>{{ payment.schedule.group.name }}</td>
              <td>{{ payment.schedule.space_location.name }}</td>
              <td>{{ payment.amount }}</td>
              <td>{{ payment.payment_method }}</td>
              <td>{{ payment.schedule.start_time }}</td>
            </tr>
          </tbody>
        </table>
      </template>

      <div class="col-6 text-center">
        <form action="" @submit.prevent="AddToBlacklist">
          <div class="form-group">
            <div class="row">
              <div class="col-3">Artist</div>
              <div class="col-9">
                <input
                  id="blacklist_artist"
                  class="form-control"
                  type="text"
                  v-model="blacklist_artist"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-3"></div>
              <div class="col-9">
                <input
                  class="btn btn-danger disabled"
                  disabled
                  type="submit"
                  value="Add"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#ArtistBlacklist {
  background: white;
  padding-top: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  .table {
    background: white;
    border: 1px solid #ccc;
    border-radius: 1px;
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
